(function () {
  'use strict';

  angular
    .module('settings.events')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('settings.events', {
        abstract: true,
        url: '/events',
        templateUrl: 'settings/events/events.tpl.html'
      })
    ;
  }
}());
